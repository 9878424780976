
import Cookies from "js-cookie";
//import React, { createContext, useReducer, useEffect, useContext } from "react";

// import { fetchAuthSession } from 'aws-amplify/auth'
// import { createContext, useCallback, useEffect, useReducer } from 'react';
// import { CognitoUser, CognitoUserPool, AuthenticationDetails } from 'amazon-cognito-identity-js';
//import { CognitoUser, CognitoUserPool, AuthenticationDetails } from 'amazon-cognito-identity-js';
//import { fetchAuthSession } from 'aws-amplify/auth';
//import { Auth } from 'aws-amplify';

export const isAuthenticated = () => {  
     console.log("--------X---------"); 
     let resp = Cookies.get('response');
     console.log(resp); 
    // console.log(Cookies);

    //let info = Auth.currentSession()
    //let userName = info.idToken.payload['cognito:username']

    //const session = fetchAuthSession();
    //const token = session.tokens?.idToken;
    //eu-west-1_BFIwU9oaD
    // console.log(fetchAuthSession);
    // console.log((fetchAuthSession()).tokens);
    // const authToken = (fetchAuthSession()).tokens?.idToken?.toString();
    //console.log(authToken);
    // console.log(AuthenticationDetails);
    console.log("--------X---------"); 
    // var cognitoUser = CognitoUserPool.
    // console.log(cognitoUser);
    // console.log("--------X---------"); 
    // console.log(AuthenticationDetails);

    // const authToken = (fetchAuthSession()).tokens?.idToken?.toString();

    // console.log(authToken);

    return Cookies.get('code');    
};
