import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"
import Topbar from "./components/Layout/Topbar/Topbar";
import Footer from "./components/Layout/Footer/Footer";
import { Navbar } from "./components/Layout/Navbar/NavigationPane"
import { QueryPageLayout } from "./components/Layout/Pages/Query"
import {Results} from "./components/Layout/Pages/Results";
import Reports from "./components/Layout/Pages/Reports";
import Kyc from "./components/Layout/Pages/Kycdocs";
import SALTThreshold from "./components/Layout/Pages/SALTThreshold";
import TravellineThreshold from "./components/Layout/Pages/TravellineThreshold";
import Summary from "./components/Layout/Pages/SummaryNew2";
import { QueryProvider } from "./context/QueryContext";
import "./App.css"
import AddressBox from "./components/Layout/Pages/AddressBox";

function App() {
  return (
    <div className="app">
      <Container fluid>
        <BrowserRouter>
          <QueryProvider>
            <Row>
              <Topbar />
            </Row>
            <Row>
              { <Navbar /> }
            </Row>
            <Row>
              <Switch>
                <Route path="/query" exact={true}
                  component={(params) => <QueryPageLayout/>}
                />
                <Route 
                  exact path="/results"
                  component={(params) => <Results />} 
                />
                {/* <Route 
                  exact path="/reports"
                  component={(params) => <Reports />} 
                /> */}
                <Route 
                   exact path="/reports"
                   component={(params) => <Summary />} 
                />
                <Route 
                   exact path="/kyc"
                   component={(params) => <Kyc />} 
                />
                <Route 
                   exact path="/SALTThreshold"
                   component={(params) => <SALTThreshold />} 
                />
                 <Route 
                   exact path="/travellineThreshold"
                   component={(params) => <TravellineThreshold />} 
                />
                {process.env.REACT_APP_ENVIRONMENT ==="dev"?(
                  <Route 
                  exact path="/search"
                  component={(params) => <AddressBox />} 
                />):([])}
                <Redirect to="/query" />
              </Switch>
            </Row>
            <Row>
              <Col sm={12} md={12} lg={12}><Footer /></Col>
            </Row>
          </QueryProvider>
        </BrowserRouter>
      </Container>
    </div>
  );
}

export default App;