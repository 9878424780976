// /* eslint-disable react-hooks/exhaustive-deps */
// /* eslint-disable no-throw-literal */
import React, { useContext, useEffect, useRef, useCallback, useMemo, useState, StrictMode, } from 'react'
import DataGrid from "react-data-grid";
//import "react-data-grid/dist/react-data-grid.css";
// import { Row, Col } from "antd";
// import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
// // eslint-disable-next-line
// import "antd/dist/antd.min.css";
// import "bootstrap/dist/css/bootstrap.css";
//import { ResultsDataTable } from "./ResultsDataTable";
import { QueryContext } from "../../../context/QueryContext";
//import DropDown from "./CustomDropDown/CustomDropDown";
import createRowDataTravelline from "./createRowDataTravelline";
// //import { createRoot } from "react-dom/client";
import './Threshold.css';





function rowKeyGetter(row) {
  return row.id;
}

const currencyFormatter = new Intl.NumberFormat(navigator.language, {
  style: "currency",
  currency: "usd"
});




export const TravellineThreshold = (props) => {

    const { queryState, queryDispatch } = useContext(QueryContext);
    const [queryResult, setQueryResult] = useState(null);

    const [rows, setRows] = useState(createRowDataTravelline());

  const columns = [
    { key: "Channel", name: "Channel" },
    { key: "Issuers", name: "Issuers" },
    { key: "MID", name: "MID" },
    { key: "Fulfilments", name: "Fulfilments" },
    { key: "OrderTypes", name: "OrderTypes" },
    { key: "ThreshHoldLimit", name: "ThreshHoldLimit" }
    // {
    //   key: "ThreshHoldLimit",
    //   name: "ThreshHoldLimit",
    //   editable: true,
    //   resizable: true,
    //   formatter: (props) => (
    //     <DropDown {...props} onChange={handleDropDownChange} />
    //   )
    // },
  
  ];

  // const handleDropDownChange = (id, value) => {
  //   console.log("Dropdown value changed for id:", id, "with value: ", value);
  //   setRows([{ ...rows[id], jobType: value }]);
  // };

  // function onRowReorder(fromIndex, toIndex) {
  //   const newRows = [...rows];
  //   newRows.splice(toIndex, 0, newRows.splice(fromIndex, 1)[0]);
  //   setRows(newRows);
  // }

  const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    setRows((rows) => {
      const updatedRows = rows.slice();
      // console.log(rows);
      for (let i = fromRow; i <= toRow; i++) {
        updatedRows[i] = { ...updatedRows[i], ...updated };
      }
      return { updatedRows };
    });
  };
    

    useEffect(() => {
        // console.log("Results use effect :: ", queryState.response);
        // if(!queryResult && queryState.response){
        //     // console.log("Results test 1 :: ", queryState.response.message);
        //     // let response = JSON.parse(queryState.response)
        //     // setQueryResult(response.finalResponse ? (response.finalResponse):(response));
        // }
    });

    

    return (
        <React.Fragment>
            <div style={{ backgroundColor: "rgb(249, 251, 255)" }}>
                <DataGrid
                    rowKeyGetter={rowKeyGetter}
                    columns={columns}
                    rows={rows}
                    rowGetter={(i) => rows[i]}
                    height={600}
                    onGridRowsUpdated={onGridRowsUpdated}
                    onRowsChange={onGridRowsUpdated}
                  />
            </div>
        </React.Fragment>
    )
}
  
export default TravellineThreshold